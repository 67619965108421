// extracted by mini-css-extract-plugin
export var bounce = "thankyou-module--bounce--8Y3Hm";
export var box = "thankyou-module--box--IIGNP";
export var boxButton = "thankyou-module--box-button--9sS7r";
export var boxDescription = "thankyou-module--box-description--Z4LOs";
export var line = "thankyou-module--line--rOabi";
export var line1 = "thankyou-module--line-1--JmDmz";
export var line1Move = "thankyou-module--line-1-move--5vvob";
export var line2 = "thankyou-module--line-2---2seh";
export var line2Move = "thankyou-module--line-2-move--4aS-f";
export var line3 = "thankyou-module--line-3--GJfDa";
export var line3Move = "thankyou-module--line-3-move--pYvob";
export var line4 = "thankyou-module--line-4--O3-99";
export var line4Move = "thankyou-module--line-4-move--SzBUb";
export var line5 = "thankyou-module--line-5--x6OFq";
export var line5Move = "thankyou-module--line-5-move--5AyLy";
export var lineContainer = "thankyou-module--line-container--UEvyJ";
export var mail = "thankyou-module--mail--WkM4s";
export var mailContainer = "thankyou-module--mail-container--FWsR1";
export var mailMove = "thankyou-module--mail-move--l96Nr";
export var marginLeft05rem = "thankyou-module--margin-left-05rem--KIiWD";
export var marginTop15rem = "thankyou-module--margin-top-1-5rem--3QUCN";
export var marginTopMinus05rem = "thankyou-module--margin-top-minus-05rem--29lJn";
export var paddingBottom1rem = "thankyou-module--padding-bottom-1rem--6e0iz";
export var paddingTop1rem = "thankyou-module--padding-top-1rem--05WJO";
export var redirect = "thankyou-module--redirect--nZKg6";
export var shine = "thankyou-module--shine--U4sG9";
export var thanksMessage = "thankyou-module--thanks-message--ZGK6b";